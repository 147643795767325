import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {

  static targets = ['alert']
  
  alertTargetConnected() {
    this.alertTargets.forEach((target) => {
      this.display(target);
      setTimeout(() => {
        this.dismiss(target);
      }, 5000);
    })
  }
  
  close(e) {
    if (e.srcElement.nodeName == 'svg' || e.srcElement.nodeName == 'BUTTON' || e.srcElement.nodeName == 'path') {
      this.dismiss(e.currentTarget);
    }
  }

  dismiss(element) {
    element.classList.replace('ease-out', 'ease-in');
    element.classList.replace('duration-300', 'duration-100');
    element.classList.replace('opacity-100', 'opacity-0');
    element.classList.add('max-h-28');
    setTimeout(() => {
      element.classList.replace('max-h-28', 'hidden');
    }, 200);
    
  }
  
  display(element) {    
    element.classList.replace('translate-y-2', 'translate-y-0');
    element.classList.replace('opacity-0', 'opacity-100');
    element.classList.replace('sm:translate-x-2', 'sm:translate-x-0');
    element.classList.add('sm:translate-y-0');
  }
  
  
  

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {

  static targets = ['mobileOpen', 'mobileClose', 'mobileMenu', 'mobileSearchMenu', 'slideoutSidebar']

  toggleMobileMenu() {
    // Open/close menu
    this.mobileMenuTarget.classList.toggle('hidden');
    this.mobileMenuTarget.classList.toggle('block');

    // Show/Hide menu open button
    this.mobileOpenTarget.classList.toggle('hidden');
    this.mobileOpenTarget.classList.toggle('block');

    // Show/Hide menu close button
    this.mobileCloseTarget.classList.toggle('hidden');
    this.mobileCloseTarget.classList.toggle('block');
  }

  mobileMenuTargetConnected(element) {
    var submenues = element.querySelectorAll('.dropdown');

    [].forEach.call(submenues, (submenu) => {
      submenu.addEventListener('click', () => {

        // Close all other menus
        [].forEach.call(submenues, (sub) => {
          if (submenu != sub) {
            sub.classList.remove('open');
          }
        });

        // Toggle Menu open/close
        submenu.classList.toggle('open');
      });
    });

  }

  slideoutSidebarTargetConnected(element) {
    let state = localStorage.getItem("slideout");
    if (state == 'open') {
      element.classList.add('open');
    }
  }

  toggleSidebarSlideout() {
    this.setSlideoutState();

    this.slideoutSidebarTarget.classList.add('transitioning');
    this.slideoutSidebarTarget.classList.toggle('open');

    setTimeout(() => {
      this.slideoutSidebarTarget.classList.remove('transitioning');
    }, 500);
  }

  setSlideoutState(state = false) {

    if (state) {
      localStorage.setItem("slideout", state);
    } else {
      let isOpen = this.slideoutSidebarTarget.classList.contains('open');

      if (isOpen) {
        localStorage.setItem("slideout", "closed");
      } else {
        localStorage.setItem("slideout", "open");
      }
    }

  }

  resetSlideoutState() {
    if (window.innerWidth >= 1024) {
      this.closeSlideoutState();
    }
  }

  closeSlideoutState() {

    if (this.slideoutSidebarTarget.classList.contains('open')) {
      this.slideoutSidebarTarget.classList.add('transitioning');
      this.slideoutSidebarTarget.classList.remove('open');

      this.setSlideoutState('closed');
      setTimeout(() => {
        this.slideoutSidebarTarget.classList.remove('transitioning');
      }, 500);
    }
  }


}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="listing"
export default class extends Controller {

  static values = {
    url: String
  }

  toggleArchive() {
    console.log(this.urlValue);

    var url = this.urlValue;

    fetch(url, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then( (response) => {

    });

  }

}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dashboard"
export default class extends Controller {
  
  static targets = ['activityFeed', 'activeUsersCount', 'demographicsAgeChart', 'demographicsGenderChart', 'totalViews', 'totalVisitors']
  
  
  demographicsAgeChartTargetConnected() {
    let that = this;
    let demographicsAgeChartDataPromise = this.firebaseController.analyticsDataLast30Days();
    demographicsAgeChartDataPromise.then(function(results) {
      
      if (results.demographics.age) {
        let values = {}
        for (const item in results.demographics.age) {
          values[item] = results.demographics.age[item];
        }
        
        that.chartController.initBarChart( values, 'demographicsAge', 'ageLegend');
      }
      
    }, failureCallback);
  }  
  
  demographicsGenderChartTargetConnected() {
    let that = this;
    let demographicsGenderChartDataPromise = this.firebaseController.analyticsDataLast30Days();
    demographicsGenderChartDataPromise.then(function(results) {
      
      if (results.demographics.gender) {
        let values = {}
        for (const item in results.demographics.gender) {
          values[item] = results.demographics.gender[item];
        }
        
        that.chartController.initPieChart( values, 'demographicsGender', 'genderLegend');
      }
      
    }, failureCallback);
  }
  
  totalViewsTargetConnected() {
    let that = this;
    let totalViewsDataPromise = this.firebaseController.analyticsDataLast30Days();
    totalViewsDataPromise.then(function(results) {
      if (results.views) {
        that.totalViewsTarget.innerHTML = results.views.toLocaleString();
      }
    }, failureCallback);
  }  
  
  totalVisitorsTargetConnected() {
    let that = this;
    let totalVisitorsDataPromise = this.firebaseController.analyticsDataLast30Days();
    totalVisitorsDataPromise.then(function(results) {
      if (results.visits) {
        that.totalVisitorsTarget.innerHTML = results.visits.toLocaleString();
      }
    }, failureCallback);
  }
  
  get firebaseController() {
    var activityFeedElement = document.getElementById('firebase')
    return this.application.getControllerForElementAndIdentifier(activityFeedElement, 'firebase');
  }  
  
  get chartController() {
    var chartElement = document.getElementById('charts')
    return this.application.getControllerForElementAndIdentifier(chartElement, 'charts');
  }
  
}

function failureCallback(err) {
  console.log("An error occured: " + err);
}
import Sortable from 'sortablejs'
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="draggable"
export default class extends Controller {
  
  static targets = ['linkList', 'testimonialList', 'profileFeaturedListingsList']
  
  static values = {
    linkUrl: String,
    testimonialUrl: String,
    profileFeaturedListingsUrl: String

  }
  
  linkListTargetConnected(element) {
    let url = this.linkUrlValue
    
    var sortable = Sortable.create(element, {
      handle: ".handle",
      onEnd: function(event) {
        var order = sortable.toArray();
        var str = JSON.stringify(order);
        const newOrder = JSON.stringify(JSON.parse("{\"order\":" + str + "}"));
        
        fetch(url, {
            method: 'PUT',
            body: newOrder,
            headers: {
               'Content-type': 'application/json; charset=UTF-8'
            }
         });
        
      }
    });
    
  }
  
  testimonialListTargetConnected(element) {
    let url = this.testimonialUrlValue
    
    var sortable = Sortable.create(element, {
      handle: ".handle",
      onEnd: function(event) {
        var order = sortable.toArray();
        var str = JSON.stringify(order);
        const newOrder = JSON.stringify(JSON.parse("{\"order\":" + str + "}"));
        
        fetch(url, {
            method: 'PUT',
            body: newOrder,
            headers: {
               'Content-type': 'application/json; charset=UTF-8'
            }
         });
        
      }
    });
    
  }

  
  profileFeaturedListingsListTargetConnected(element) {
    let url = this.profileFeaturedListingsUrlValue
    
    var sortable = Sortable.create(element, {
      handle: ".handle",
      onEnd: function(event) {
        var order = sortable.toArray();
        const newOrder = JSON.stringify(order)
        
        fetch(url, {
            method: 'PUT',
            body: newOrder,
            headers: {
               'Content-type': 'application/json; charset=UTF-8'
            }
         });
        
      }
    });
    
  }
}

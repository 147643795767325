import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="favorite"
export default class extends Controller {

  static values = {
    listing: String,
    addUrl: String,
    removeUrl: String,
    favorited: Boolean
  }

  toggle(e) {
    var favoriteButton = e.target;
    var id = this.listingValue;
    var url;
    var that = this;

    favoriteButton.classList.toggle('favorited');

    if (this.favoritedValue) {
      url = this.removeUrlValue;
    } else {
      url = this.addUrlValue;
    }

    fetch(url, {
      method: "put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then( (response) => {
      let frameElement = document.getElementById('listing-' + that.listingValue);
      let alertFrameElement = document.getElementById('flash');
      frameElement.src = location;
      alertFrameElement.src = location;
      frameElement.reload();
      alertFrameElement.reload();
    });
  }

}

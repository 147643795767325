import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="predictive-search"
export default class extends Controller {

  static targets = ['autoCompleteField']

  static values = {
    endpoint: String
  }

  async autoCompleteFieldTargetConnected(element) {

    var autoCompleteArray = await this.getData(this.endpointValue);

    if (autoCompleteArray != 'undefined') {
      autoComplete(element, autoCompleteArray);
    }
  }

  async getData(endpoint) {
    var data = [];

    let queryUrl = endpoint + '?' + urlParams();

    try {
      const response = await fetch(queryUrl);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      return json;

    } catch (error) {
      console.error(error.message);
    }
  }

}

function urlParams(param = null) {

  let params = new URLSearchParams(document.location.search);
  if (param) {
    var result = params.get(param);
    if (result == 'undefined') {
      return '';
    } else {
      return '' + result;
    }
  } else {
    return params.toString();
  }

}

function autoComplete(input, array = []) {
  let currentFocus;
  let resultCountLimit = 10;
  var resultCount = 0;

  input.addEventListener("input", function() {
      let div, item, val = this.value;

      closeAllLists();
      if (!val) return false;
      currentFocus = -1;

      div = document.createElement("DIV");
      div.setAttribute("id", this.id + "autocomplete-list");
      div.setAttribute("class", "autocomplete-items");
      this.parentNode.appendChild(div);

      array.sort((a, b) => b.count - a.count).forEach((arrayItem) => {

        if (arrayItem.name.substr(0, val.length).toUpperCase() === val.toUpperCase() && resultCount <= resultCountLimit && !urlParams('locations').includes(arrayItem.value)) {
            resultCount += 1;
            item = document.createElement("DIV");
            item.classList.add('location-suggestion');
            item.innerHTML = "<i class='fa-solid fa-circle-plus add'></i>";
            item.innerHTML += "<strong>" + arrayItem.name.substr(0, val.length) + "</strong>";
            item.innerHTML += [arrayItem.name.substr(val.length), arrayItem.state, arrayItem.country].filter((x) => x != null).join(', ');
            item.innerHTML += ' (' + arrayItem.count.toLocaleString("en-US") + ')';

            item.addEventListener("click", function() {
                var input = document.querySelector('input[name=locations]');
                var currentVal = input.value;
                input.value = (currentVal == null || currentVal == '') ? arrayItem.value : [currentVal, arrayItem.value].join(',');
                input.form.dispatchEvent(new CustomEvent('submit', { bubbles: true }));
                closeAllLists();
            });
            div.appendChild(item);
        }
      });
  });

  input.addEventListener("keydown", function(e) {
      resultCount = 0;
      let list = document.getElementById(this.id + "autocomplete-list");
      if (list) list = list.getElementsByTagName("div");
      if (e.keyCode === 40) {
          currentFocus++;
          addActive(list);
      } else if (e.keyCode === 38) {
          currentFocus--;
          addActive(list);
      } else if (e.keyCode === 13) {
          e.preventDefault();
          if (currentFocus > -1) {
              if (list) list[currentFocus].click();
          }
      }
  });

  function addActive(list) {
      if (!list) return false;
      removeActive(list);
      if (currentFocus >= list.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = (list.length - 1);
      list[currentFocus].classList.add("autocomplete-active");
  }

  function removeActive(list) {
      for (let i = 0; i < list.length; i++) {
          list[i].classList.remove("autocomplete-active");
      }
  }

  function closeAllLists(elmnt) {
      const items = document.getElementsByClassName("autocomplete-items");
      for (let i = 0; i < items.length; i++) {
          if (elmnt !== items[i] && elmnt !== input) {
              items[i].parentNode.removeChild(items[i]);
          }
      }
  }

  document.addEventListener("click", function(e) {
      closeAllLists(e.target);
  });
}



// Import Firebase
import { initializeApp } from "firebase/app";
import { enableIndexedDbPersistence, enableMultiTabIndexedDbPersistence, getFirestore, collection, doc, getDoc, onSnapshot, query, where, orderBy, limit, setLogLevel} from 'firebase/firestore';
import { isSafari } from '@firebase/util';

var db, analyticsUnsubscribe, activityFeedUnsubscribe;

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['activeUsersCount', 'totalViews', 'totalVisitors', 'activityFeeds', 'accountActivityFeed', 'networkActivityFeed']

  static values = {
    environment: String,
    apikey: String,
    authdomain: String,
    projectid: String,
    accountid: Number,
    profileid: Number,
    userType: String
  }

  initialize() {
    
    if (typeof db == 'undefined') {
      this.setUpFirestore();
    }
    
  }
  
  setUpFirestore() {
    
    // Firebase configuration
    const firebaseConfig = {
      apiKey: this.apikeyValue,
      authDomain: this.authdomainValue,
      projectId: this.projectidValue
    };
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    db = getFirestore(app);
    // setLogLevel('debug');
    
    // enableIndexedDbPersistence(db, {forceOwnership: true});
    if (!isSafari()) {
      enableMultiTabIndexedDbPersistence(db);
    }
    
  }

  async dashboardData(accountId) {
    
    // Don't show Special Feature Account Data
    if (this.userTypeValue == 'staff') {accountId = 0}
    
      const dashboardDoc = this.dashboardDoc(accountId);
      const docSnap = await getDoc(dashboardDoc);
      if (docSnap.exists()) {
        let dataToSave = {
          createdAt: Date.now(),
          data: docSnap.data()
        };
        
        return docSnap.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
      }
      
  }
  
  async analyticsDataLast30Days() {
    
    const analyticsLast30DaysDoc = this.analyticsLast30DaysDoc();
    const docSnap = await getDoc(analyticsLast30DaysDoc);
    if (docSnap.exists()) {
      let dataToSave = {
        createdAt: Date.now(),
        data: docSnap.data()
      };
      
      return docSnap.data();
      
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
      
  }
   
  accountActivityFeedTargetConnected() {
    let accountID = this.accountidValue;
    if (accountID != 0 && accountID != null && this.userTypeValue != 'staff') {
      this.populateActivityStream(true);
    } else {
      this.accountActivityFeedTarget.classList.add('hidden');
    }
  }
  
  networkActivityFeedTargetConnected() {
    this.populateActivityStream(false);
  }
  
  populateActivityStream(accountOnly = true) {
    
    var initialLoad = true;
    let that = this;
    let elements = accountOnly ? this.accountActivityFeedTargets : this.networkActivityFeedTargets;
    let query = accountOnly ? this.accountActivityFeedDataQuery() : this.siteActivityFeedDataQuery();
    
    activityFeedUnsubscribe = onSnapshot(query, (activityStreamDataQuerySnapshot) => {
      
      setTimeout(function() {
        initialLoad = false;
      }, 2000);
      
        
      if (activityStreamDataQuerySnapshot.docChanges().length == 0) {
        elements.forEach(function(element) {
          if (element.innerHTML == '') {
            element.insertAdjacentHTML("afterbegin", that.activityFeedController.emptyTemplate());
          }
        });
      } else {
        
        activityStreamDataQuerySnapshot.docChanges().forEach((change, index) => {
          let item = change.doc.data();
          var additions = 0;
          activityStreamDataQuerySnapshot.docChanges().forEach((item) => {
            if (item.type == 'added') {additions++}
          });
          
          if (change.type == 'added') {
            
            let noRecentActivity = document.getElementById('no-recent-activity');
            
            if (noRecentActivity) {
              document.getElementById('no-recent-activity').classList.add("hidden");
            }
            
            // Check item is not already on page
            if ( document.getElementById('activity-'+item.id) ) {
              
              // If present replace activity item
              let feedItem = document.getElementById('activity-'+item.id);
              feedItem.innerHTML = that.activityFeedController.feedItemTemplate(item, {'returnChild': true});
            } else {
              
              // If not present, add activity item
              elements.forEach(function(element) {
                let firstItem = element.firstChild;
                if (firstItem && (Number(item.id) <= Number(firstItem.dataset.itemId))) {
                  element.insertAdjacentHTML("beforeend", that.activityFeedController.feedItemTemplate(item, {'initialLoad': initialLoad}));
                } else if (additions == 1) {
                  element.insertAdjacentHTML("afterbegin", that.activityFeedController.feedItemTemplate(item, {'initialLoad': initialLoad}));
                } else {
                  element.insertAdjacentHTML("beforeend", that.activityFeedController.feedItemTemplate(item, {'initialLoad': initialLoad}));
                }
              });
            }            
            
          } else if (change.type == 'modified') {
            let feedItem = document.getElementById('activity-'+item.id);
            feedItem.innerHTML = that.activityFeedController.feedItemTemplate(item, {'returnChild': true});
          } else if (change.type == 'removed') {
            let feedItem = document.getElementById('activity-'+item.id);
            if (feedItem) {
              
              feedItem.classList.add('removed');
              setTimeout(function() {
                feedItem.remove();
              }, 700);
            }
          }
          
        });
        
      }
      
    });
    
  }

  dashboardDoc(accountId) {
    const environment = this.firebaseEnvironmentToUse();
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const dashboardDataRef = collection(serverDoc, 'dashboardData');
    const dashboardAccountDoc = doc(dashboardDataRef, 'account-' + accountId);
    return dashboardAccountDoc
  }
  
  analyticsLast30DaysDoc() {
    const environment = this.firebaseEnvironmentToUse();
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const dashboardDataRef = collection(serverDoc, 'analytics');
    const dashboardAccountDoc = doc(dashboardDataRef, 'last30Days');
    return dashboardAccountDoc
  }

  analyticDataQuery() {
    const environment = this.firebaseEnvironmentToUse();
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const analyticsDataRef = collection(serverDoc, 'analytics');
    const q = query(analyticsDataRef);
    return q
  }
  
  accountActivityFeedDataQuery() {
    const environment = this.firebaseEnvironmentToUse();
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const activityFeedDataRef = collection(serverDoc, 'activityFeed');
    const q = query(activityFeedDataRef, where("context", "array-contains-any", ['a'+this.accountidValue, 'p'+this.profileidValue]), orderBy("createdAt", "desc"), limit(10));
    return q
  }
  
  siteActivityFeedDataQuery() {
    const environment = this.firebaseEnvironmentToUse();
    const environmentRef = collection(db, environment);
    const serverDoc = doc(environmentRef, this.serverDomain());
    const activityFeedDataRef = collection(serverDoc, 'activityFeed');
    const q = query(activityFeedDataRef, orderBy("createdAt", "desc"), limit(10));
    return q
  }

  // Root Firestore Document
  serverDomain() {
    switch (this.firebaseEnvironmentToUse()) {
      case 'development':
        return window.location.hostname.replace('www.', '').replace('lounge.', '');
        break;
      case 'staging':
        return 'staging.luxre.net';
        break;
      default:
        return 'luxuryrealestate.com';
    }
  }
  
  firebaseEnvironmentToUse() {
    
    if (this.environmentValue == 'development' || this.environmentValue == 'staging') {
      
      const urlParams = new URL(window.location.toLocaleString()).searchParams;
      const envParam = urlParams.get('env');
      const environmentParam = urlParams.get('environment');
      let environmentToUse = (envParam == "production" || environmentParam == "production") ? 'production' : this.environmentValue;
      
      return environmentToUse;
      
    } else {
      return this.environmentValue;
    }
    
  }
  
  get activityFeedController() {
    var activityFeedElement = document.querySelector('.activity-wrapper')
    
    return this.application.getControllerForElementAndIdentifier(activityFeedElement, 'activity');
  }  

}

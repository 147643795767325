import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="spinner"
export default class extends Controller {
  
  replace(event) {
    let element = event.target;
    let spinners = document.getElementsByClassName(element.dataset.spinner);
    
    // Hide element
    element.classList.add('hidden');
    
    // Display spinner
    [].forEach.call(spinners, (spinner) => {
      spinner.classList.remove('hidden');
    });
    
  }
  
}

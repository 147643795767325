import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigation"
export default class extends Controller {

  updateURL(event, url = false) {

    var target = event.target;
    var newURL;
    if (url) {
      newURL = url;
    } else if ( target.hasAttribute('href') ) {
      newURL = target.href;
    } else if ( target.parentElement.hasAttribute('href') ) {
      newURL = target.parentElement.href;
    } else if ( target.parentElement.parentElement.hasAttribute('href') ) {
      newURL = target.parentElement.parentElement.href;
    } else {
      return null;
    }

    newURL = newURL.replaceAll('%2C', ','); // allow commas
    newURL = newURL.replaceAll('%5B', '[').replaceAll('%5D', ']'); // allow brackets
    history.replaceState({}, "", newURL);
  }

  updatePageContentAndURL(url) {
    document.getElementById('page-content').src = url;
    this.updateURL({}, url);
  }

  updatePageContentAndURLWithSelect(event) {
    const that = this;
    const selectedValue = event.target.selectedOptions[0].value;
    if (selectedValue) {
      that.updatePageContentAndURL(selectedValue);
    }
  }



}

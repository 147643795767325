import Cropper from 'cropperjs';
import { Controller } from "@hotwired/stimulus"

var cropper;

// Connects to data-controller="image"
export default class extends Controller {
  
  cropImage(image, existingImage = true) {
    var that = this;
    cropper = new Cropper(image, {
      checkCrossOrigin: false,
      zoomable: false,
      restore: false,
      aspectRatio: 1,
      rotatable: false,
      scalable: false,
      ready: function () {
        var cropper = this.cropper;
        
        if (existingImage) {
          var existingCropData = document.getElementById('crop-data').value.split(',');
          
          cropper.setData({
            x: parseInt(existingCropData[0]),
            y: parseInt(existingCropData[1]),
            width: parseInt(existingCropData[2]),
            height: parseInt(existingCropData[3])
          });
        } else {
          that.setCropData();
        }
        this.cropper.crop();

      },
    
      cropend: function (e) {
        that.setCropData();
      }
    });
    
  }
  
  destroyCropper() {
    if (cropper) {
      cropper.destroy();
      var image = document.querySelector("img#image-to-crop");
      image.src = image.dataset.ogImg;
    }
  }
  
  setCropData(cropData) {
    var cropData = cropper.getData(true);
    var imageData = cropper.getImageData();
    
    var maxX = imageData.naturalWidth - 1 - cropData.width < 0 ? 0 : imageData.naturalWidth - 1 - cropData.width;
    var maxY = imageData.naturalHeight - 1 - cropData.height < 0 ? 0 : imageData.naturalHeight - 1 - cropData.height;
    var maxDimension = imageData.naturalWidth > imageData.naturalHeight ? imageData.naturalHeight - 1 : imageData.naturalWidth - 1;
    var cropWidth = cropData.width > maxDimension ? maxDimension : cropData.width;
    var cropHeight = cropData.height > maxDimension ? maxDimension : cropData.height;
    var cropX = cropData.x < 0 ? 0 : cropData.x;
    var cropY = cropData.y < 0 ? 0 : cropData.y;
    cropX = cropX > maxX ? maxX : cropX;
    cropY = cropY > maxY ? maxY : cropY;
    
    var dataString = cropX + ',' + cropY +',' + cropWidth + ',' + cropHeight;
    var cropDataField = document.querySelector('input#crop-data');
    cropDataField.value = dataString;
  }

}

import dayjs from 'dayjs' // ES 2015
let relativeTime = require('dayjs/plugin/relativeTime')
let updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "A few seconds",
    m: "A minute",
    mm: "%d minutes",
    h: "An hour",
    hh: "%d hours",
    d: "A day",
    dd: "%d days",
    M: "A month",
    MM: "%d months",
    y: "A year",
    yy: "%d years"
  }
})

let intervId;

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="activity"
export default class extends Controller {
  
  static targets = ['accountToggle', 'networkToggle', 'list']
  
  connect() {
    if (!intervId) {
      intervId = setInterval(updateDayjsTimes, 60000); // Update activity times once a minute if they are newer then 
    }
  }  
  
  disconnect() {
    clearInterval(intervId);
  }

  feedItemTemplate(item, options = {'initialLoad': true, 'returnChild': false}) {
    var type = item.context == 'recentBlogPost' ? 'blog-post' : 'content-post';
    const createdAt = new Date(item.createdAt.seconds * 1000);
    switch (item.activity_type) {
      case 'custom':
        return this.customFeedItemTemplate(item, createdAt, options);  
      case 'ticket_purchase':
        return this.ticketPurchasedFeedItemTemplate(item, createdAt, options);
      case 'purchase':
        return this.purchaseFeedItemTemplate(item, createdAt, options);
      case 'blog_comment':
        return this.blogCommentFeedItemTemplate(item, createdAt, options);       
      case 'blog_post':
        return this.blogPostFeedItemTemplate(item, createdAt, options);      
      case 'inquiry_received':
        return this.inquiryReceivedFeedItemTemplate(item, createdAt, options);
      case 'listing_saved':
      case 'vacation_rental_saved':
      case 'development_saved':
      case 'vacation_rental_emailed_to_friend':
        return this.listingFeedItemTemplate(item, createdAt, options);
      default:
        return this.defaultFeedItemTemplate(item, createdAt, options);
    }

  }
  
  populateAccountActivity() {
    this.firebaseController.populateActivityStream();
    this.accountToggleTarget.classList.add('active');
    this.networkToggleTarget.classList.remove('active');
  }  

  populateNetworkActivity() {
    this.firebaseController.populateActivityStream(false);
    this.networkToggleTarget.classList.add('active');
    this.accountToggleTarget.classList.remove('active');
  }
  
  toggleAccountActivity() {
    this.firebaseController.networkActivityFeedTargets.forEach(function(networkActivityFeed) {
      networkActivityFeed.classList.add('hidden');
    });
    
    this.firebaseController.accountActivityFeedTargets.forEach(function(accountActivityFeed) {
      accountActivityFeed.classList.remove('hidden');
    });
    
    let accountToggles = document.querySelectorAll('.toggle-button.account');
    let networkToggles = document.querySelectorAll('.toggle-button.network');
    
    [].forEach.call(accountToggles, function(accountToggle) {
      accountToggle.classList.add('active');
    });  
      
    [].forEach.call(networkToggles, function(networkToggle) {
      networkToggle.classList.remove('active');
    });    
  }
  
  toggleNetworkActivity() {
    this.firebaseController.networkActivityFeedTargets.forEach(function(networkActivityFeed) {
      networkActivityFeed.classList.remove('hidden');
    });
    
    this.firebaseController.accountActivityFeedTargets.forEach(function(accountActivityFeed) {
      accountActivityFeed.classList.add('hidden');
    });
    
    let accountToggles = document.querySelectorAll('.toggle-button.account');
    let networkToggles = document.querySelectorAll('.toggle-button.network');
    
    [].forEach.call(accountToggles, function(accountToggle) {
      accountToggle.classList.remove('active');
    });  
      
    [].forEach.call(networkToggles, function(networkToggle) {
      networkToggle.classList.add('active');
    });  
  }

  
  feedItemElement(content, item, createdAt, options) {
    
    if (options.returnChild) {
      return this.feedItemElementChild(content, item, createdAt);
    } else {
      let initalClass = options.initialLoad ? 'initialLoad' : '';
      // let creation = item.createdAt.seconds;
      return `<li id="activity-${item.id}" class="activity-item ${initalClass}" data-item-id="${item.id}">
        ${this.feedItemElementChild(content, item, createdAt)}
      </li>`;
    }
  }
  
  feedItemElementChild(content, item, createdAt) {
    return `<div class="relative pb-8">
      <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-700 connector" aria-hidden="true"></span>
      <div class="relative flex space-x-3">
        ${this.profileImage(item.profile)}
        <div class="min-w-0 flex-1 flex justify-between flex-col">
          ${content}
          ${this.relativeTime(createdAt)}
        </div>
      </div>
    </div>`;
  }

  customFeedItemTemplate(item, createdAt, options) {
    var attachment;
    if (item.attachment) {
      attachment = `<p class="text-xs text-gray-500 dark:text-gray-400"><a href="${item.attachment.url}" target="_blank">${item.attachment.name}</a></p>`;
    } else {
      attachment = ``;
    }
    let content = `<div>
              <p class="text-xs text-gray-500 dark:text-gray-400">${item.message}</p>
              ${attachment}
            </div>
            `;
    return this.feedItemElement(content, item, createdAt, options);
  }

  blogPostFeedItemTemplate(item, createdAt, options) {
    let postLink = `<a href="${this.blogPostLink(item.blog_post)}" target="_blank">${item.blog_post.name}</a> `;
    let content = `<div>
              ${this.profileLink(item.profile)}
              <p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content.replace("{{link}}", postLink)}</p>
            </div>
            `;
  
    return this.feedItemElement(content, item, createdAt, options);
  }
  
  blogCommentFeedItemTemplate(item, createdAt, options) {
    let postLink = `<a href="${this.blogPostLink(item.blog_post)}">${item.blog_post.name}</a>.`;
    let content = `<div>
                    ${this.profileLink(item.profile)}
                    <p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content.replace("{{link}}", postLink)}</p>
                  </div>`;
    return this.feedItemElement(content, item, createdAt, options);
  }
  
  listingFeedItemTemplate(item, createdAt, options) {
    let listing = item.development ? item.development : item.listing;
    let listingLink = `<a href="${listing.link}" target="_blank">${listingTitle(listing)}</a>`;
    let content = `<div>
            ${this.profileLink(item.profile)}
            <a href="${listing.link}" target="_blank"><img src="${this.imageVersion(listing.image.version_base, 'w300h200_s')}"></a>
            <p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content.replace("{{link}}", listingLink)}</p>
          </div>
          `;
  return this.feedItemElement(content, item, createdAt, options);
  }
  
  inquiryReceivedFeedItemTemplate(item, createdAt, options) {
    let content = `<div>
              ${this.profileLink(item.profile)}
              <p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content}</p>
            </div>`;
    return this.feedItemElement(content, item, createdAt, options);
  }
  
  ticketPurchasedFeedItemTemplate(item, createdAt, options) {
    let eventLink = item.hasOwnProperty('event') ? `<a href="${item.event.link}" target="_blank">${item.event.name}</a>` : 'an event';
    let content = `<div>
                    ${this.profileLink(item.profile)}
                    <p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content.replace("{{link}}", eventLink)}</p>
                  </div>
                  `;
    return this.feedItemElement(content, item, createdAt, options);
  }  
  
  purchaseFeedItemTemplate(item, createdAt, options) {
    let categoryGroupPurpose = item.product?.category?.group?.purpose && item.product?.category?.name ? `<p class="text-xs text-gray-500 dark:text-gray-400 pb-1">${item.product?.category?.group?.purpose}:</p>` : '';
    let categoryName = item.product?.category?.name && item.product?.category?.group?.purpose ? `<p class="text-xs text-gray-500 dark:text-gray-400 pb-1"><a href="https://lounge.luxuryrealestate.com/services/categories/${item.product?.category?.id}">${item.product?.category?.name}</a></p>` : '';
    let activityText = item.product?.category?.activity_stream_text;
    let productLink = item.product.enabled ? `<a href="https://lounge.luxuryrealestate.com/services/${item.product.id}" target="_blank">${item.product.name}</a>` : item.product.name;
    let descriptionText = "";
    
    if (typeof item.product?.category?.name == 'undefined' || typeof item.product?.category?.group?.purpose == 'undefined') {
      descriptionText = (activityText && activityText !== undefined) ? `<p class="text-xs text-gray-500 dark:text-gray-400">${activityText}</p>` : `<p class="text-xs text-gray-500 dark:text-gray-400">${item.activity_stream_content.replace("{{link}}", productLink)}</p>`;
    }
    
    let content = `<div>
                    ${this.profileLink(item.profile)}
                    ${categoryGroupPurpose}
                    ${categoryName}
                    ${descriptionText}
                  </div>`;
      
    return this.feedItemElement(content, item, createdAt, options);
  }
  
  defaultFeedItemTemplate(item, createdAt, options) {

    let text = '';
    
    if ( item.activity_stream_content != null ) {
      text = item.activity_stream_content;
    } else if (item.content != null) {
      text = item.content;
    }
    
    var activityItemLink = '';
    var activityItemLinkTarget = '';
    
    if (typeof item.link != 'undefined' && typeof item.link.url != 'undefined' && typeof item.link.title != 'undefined' && item.link.url != null && item.link.title != null) {
      
      if (!item.link.url.includes("lounge.luxuryrealestate.com")) {
        activityItemLinkTarget = `target="_blank"`;
      }
      activityItemLink = `<p class="text-xs text-gray-500 dark:text-gray-400 pb-1"><a href="${item.link.url}" ${activityItemLinkTarget}>${item.link.title}</a></p>`;
    } 
    
    if (item.hasOwnProperty('attachment') && item.attachment != null && item.attachment.url != null) {
      
      var attachmentTypeClass = 'default-file';
      
      switch (item.attachment.content_type) {
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
          attachmentTypeClass = 'image-file';
          break;
        case 'application/pdf':
          attachmentTypeClass = 'pdf-file';
          break;        
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          attachmentTypeClass = 'word-file';
          break;
        default:
          attachmentTypeClass = 'default-file';
      }

      let itemAttachmentTitle = item.attachment.title != null ? item.attachment.title : 'Download';
      var activityItemAttachment = `<p class="text-xs text-gray-500 dark:text-gray-400 py-1">
          <a href="${item.attachment.url}" class="file-icon ${attachmentTypeClass}" target="_blank">${itemAttachmentTitle}</a>
        </p>`;
    } else {
      var activityItemAttachment = '';
    }
    
    var linkAndAttachment = '';
    let showLinkAndAttachment = item.context.includes('a' + this.firebaseController.accountidValue) || item.context.includes('p' + this.firebaseController.profileidValue) || item.context.includes(0);
    
    if (showLinkAndAttachment) {
      linkAndAttachment = `${activityItemLink}${activityItemAttachment}`;
    }
    
    let content = `<div>
              ${this.profileLink(item.profile)}
              <p class="text-xs text-gray-500 dark:text-gray-400 pb-1">${text}</p>
              ${linkAndAttachment}
            </div>
            `;
      
    if (text != '') {
      return this.feedItemElement(content, item, createdAt, options);
    } else {
      return ''
    }
  }
  
  profileImage(profile) {
    // let imageSize = profile.type == 'Agent' ? 'w70h70' : 'w70h70_nc';
    // let classes = profile.type == 'Agent' ? 'rounded-full' : 'rounded';
    let imageSize = 'w70h70';
    let classes = 'rounded-full profile-img';
    if (profile.is_on_lre) {
      return `<div>
        <span class="h-8 ${classes} bg-gray-400 flex items-center justify-center ring-4 ring-white dark:ring-gray-700">
          <a href="${this.profileLink(profile, true)}" data-turbo="false">
            <img src="${profile.image.version_base.replace('size', imageSize)}" class="${classes} h-8">
          </a>
        </span>
      </div>`;
    } else {
      return `<div>
        <span class="h-8 ${classes} bg-gray-400 flex items-center justify-center ring-4 ring-white dark:ring-gray-700">
          <img src="${profile.image.version_base.replace('size', imageSize)}" class="${classes} h-8">
        </span>
      </div>`;
    }

  }
  
  emptyTemplate() {
    return `<div id="no-recent-activity">
      <p class="text-xs text-gray-500 dark:text-gray-400">No recent activity</p>
    </div>`;
  }
  
  profileLink(profile, urlOnly = false) {
    var profileLinkUrl;
    
    if (profile.type == "Agent" || profile.type == "Staff") {
      profileLinkUrl = profile.link ? profile.link : `https://lounge.luxuryrealestate.com/directory/${profile.id}`;
    } else {
      profileLinkUrl = profile.link ? profile.link : (profile.is_on_lre ? `https://www.luxuryrealestate.com/profiles/${profile.id}` : `https://lounge.luxuryrealestate.com/directory/${profile.id}`);
    }
    
    if (urlOnly) {
      return profileLinkUrl;
    } else {
      return `<a href="${profileLinkUrl}" class="profile-link" data-turbo="false">${profile.name}</a>`
    }
  }
  
  relativeTime(createdAt) {
    let creationDate = dayjs(createdAt);
    return `<div class="text-xs whitespace-nowrap text-gray-400 dark:text-gray-500 text-[0.7rem] italic mt-1.5">
              <time class="dayjs" datetime="${creationDate.format('YYYY-MM-DD HH:mm:ss')}">${creationDate.fromNow()}</time>
            </div>`
  }
  
  blogPostLink(post) {
    return 'https://www.luxuryrealestate.com' + post.link;
  }
  
  imageVersion(base, version) {
    return base.replace('size', version);
  }
  
  get firebaseController() {
    var firebaseElement = document.getElementById('firebase')
    return this.application.getControllerForElementAndIdentifier(firebaseElement, 'firebase');
  }

}

function updateDayjsTimes() {
  let times = document.querySelectorAll('time.dayjs');
  
  [].forEach.call(times, (time) => {
    let creationDate = dayjs(time.getAttribute('datetime'));
    let newTime = creationDate.fromNow();
    time.innerHTML = newTime;
  });
}


function listingTitle(listing) {
  var listingTitle = "A listing";
  if (listing.address_one) {
    listingTitle = listing.address_one;
  }
  
  return listingTitle;
}

import {  Chart, ArcElement, BarElement, BarController, CategoryScale, LinearScale, PieController, Legend, Title, Tooltip, SubTitle } from 'chart.js';
Chart.register( ArcElement, BarElement, BarController, CategoryScale, LinearScale, PieController, Legend, Title, Tooltip, SubTitle );

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charts"
export default class extends Controller {
  
  initPieChart( values, canvasId, legendElementId = false ) {
    
    var chartData = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: [
          '#0f5879',
          '#177fae',
          '#469dc4',
          '#8ecae5',
          '#c8eeff',
          '#eeeeee',
        ],
        hoverOffset: 0
      }]
    };
    
    for (const item in sortObjectByKeys(values)) {
      chartData.labels.push(item);
      chartData.datasets[0].data.push(values[item]);
    }
    
    const config = {
      type: 'pie',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        borderColor: 'transparent',
        plugins: {
          htmlLegend: {
            containerID: legendElementId
          },
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#292929',
            borderColor: '#cdcdcd',
            borderWidth: 1,
            padding: 3,
            cornerRadius: 3,
            caretSize: 0,
            callbacks: {
              label: function(item) {
                return ' ' + item.label.replace(/^\w/, (c) => c.toUpperCase())
              },
              footer: function(item) {
                return item[0].formattedValue + '%'
              }
            }
          }
        }
      },
      plugins: [htmlLegendPlugin],
    };
    const ctx = document.getElementById(canvasId).getContext('2d');
    const pieChart = new Chart(ctx, config);
    
  }
  
  initBarChart( values, canvasId ) {
    
    var chartData = {
      labels: [],
      datasets: [{
        
        data: [],
        backgroundColor: [
          '#0f5879',
          '#177fae',
          '#469dc4',
          '#8ecae5',
          '#c8eeff',
          '#eeeeee',
        ]
      }]
    };
    
    
    for (const item in sortObjectByKeys(values)) {
      chartData.labels.push(item);
      chartData.datasets[0].data.push(values[item]);
    }
    
    let isDarkMode = document.documentElement.classList.contains('dark');
    let textColor = isDarkMode ? 'rgb(209, 213, 219)' : 'rgb(107, 114, 128)';
    
    const config = {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.5,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#292929',
            borderColor: '#cdcdcd',
            borderWidth: 1,
            padding: 3,
            cornerRadius: 3,
            caretSize: 0,
            callbacks: {
              label: function(item) {
                return ' ' + item.formattedValue + '%';
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              borderColor: 'rgba(156, 163, 175, 0.6)',
              color: 'rgba(156, 163, 175, 0.6)'
            },
            ticks: {
              color: textColor
            }
          },
          y: {
            grid: {
              borderColor: 'rgba(156, 163, 175, 0.6)',
              color: 'rgba(156, 163, 175, 0.6)'
            },
            ticks: {
              color: isDarkMode ? 'rgb(209, 213, 219)' : 'rgb(107, 114, 128)',
              callback: function(tick) {
                return tick.toString() + '%';
              }
            }
          }
        }
      },
    };
    
    const ctx = document.getElementById(canvasId).getContext('2d');
    const barChart = new Chart(ctx, config);
    
    document.addEventListener('themeChange', function(e) {
      isDarkMode = document.documentElement.classList.contains('dark');
      let textColor = isDarkMode ? 'rgb(209, 213, 219)' : 'rgb(107, 114, 128)';
      barChart.options.scales.y.ticks.color = isDarkMode ? 'rgb(209, 213, 219)' : 'rgb(107, 114, 128)';
      barChart.options.scales.x.ticks.color = isDarkMode ? 'rgb(209, 213, 219)' : 'rgb(107, 114, 128)';
      barChart.update();
    })
  }
}


// Chart Plugin
const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach(item => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const {type} = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      // boxSpan.style.borderColor = item.strokeStyle;
      // boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '20px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};

const sortObjectByKeys = (object) => Object.fromEntries(
  Object.entries(object).sort(([k1], [k2]) => k1 < k2 ? -1 : 1)
)
import { Controller } from "@hotwired/stimulus"

var jvmViewsMap;
var jvmNetworkMap = false;
var accountID;
var selectedAccount = 0;
var selectedTimeSpan = 30;
var mapviews;
var markers;

export default class extends Controller {

  static targets = ['dashboardViewsMap', 'dashboardNetworkMap', 'lists', 'moreButton']

  dashboardViewsMapTargetConnected(element) {
    accountID = this.firebaseController.accountidValue;
    
    var that = this;
    var accountViewsPromise = this.firebaseController.dashboardData(accountID);

    accountViewsPromise.then(function(results) {
      if (results) {
        let accountMapButton = document.getElementById('accountViewsButton');
        if (accountID != 0 && accountID != null && that.firebaseController.userTypeValue != 'staff') {
          accountMapButton.classList.remove('hidden');
        }
      }
      
      var siteViewsPromise = that.firebaseController.dashboardData(0);
      siteViewsPromise.then(function(siteResults) {
        if (siteResults) {
          that.initPropertyViewsMap(siteResults);
        } else {
          console.log('map error');
        }
      }, failureCallback);
      
      
    }, failureCallback);
  }

  dashboardViewsMapTargetDisconnected(element) {
    // Clear out map element to prevent duplicates on back
    element.innerHTML = '';
  }

  dashboardNetworkMapTargetDisconnected(element) {
    // Clear out map element to prevent duplicates on back
    element.innerHTML = '';
  }

  selectAccountViewsMap(e) {
    updateViewsMapTabs(e.target);
    selectedAccount = accountID;
    this.updateMapData(accountID);
    // this.firebaseController.populateActivityStream();
    
    this.dashboardViewsMapTarget.classList.remove('hidden');
    this.dashboardNetworkMapTarget.classList.add('hidden');
  }

  selectSiteViewsMap(e) {
    updateViewsMapTabs(e.target);
    selectedAccount = 0;
    this.updateMapData(0);
    // this.firebaseController.populateActivityStream(false);
    
    this.dashboardViewsMapTarget.classList.remove('hidden');
    this.dashboardNetworkMapTarget.classList.add('hidden');
  }
  
  selectNetworkMap(e) {
    updateViewsMapTabs(e.target);
    this.displayNetworkMap();
  }

  updateDataTimeSpan(e) {
    let accountButton = document.getElementById('accountViewsButton');
    let timeSpan = e.target.dataset.duration == "7" ? 7 : 30;
    selectedTimeSpan = timeSpan;
    let dataset = accountButton.classList.contains('active') ? accountID : 0;

    this.updateMapData(dataset);
    updateDurationTabs(e.target);
  }

  updateMapData(accountID) {
    var viewsPromise = this.firebaseController.dashboardData(accountID);
    viewsPromise.then(function(results) {
      let current_map = jvmViewsMap.history[jvmViewsMap.history.length - 1].params.map.replace('_mill', '');
      updateMapValues(results, current_map);
      updateTopViewsLists(results, current_map);
    }, failureCallback);
  }
  
  displayNetworkMap() {
    
    this.dashboardViewsMapTarget.classList.add('hidden');
    this.dashboardNetworkMapTarget.classList.remove('hidden');
    
    if (!jvmNetworkMap) {
      this.initNetworkMap();
    }

    this.fetchMembersList();
  }
  
  fetchMembersList() {

    markers = {
      coords: [],
      label: [],
      region: []
    };

    fetch("/members_list", {method: "post"})
    .then((response) => response.text())
    .then((data) => {

      let json = JSON.parse(data);

      json.forEach(item => {

        markers.coords.push([item.location.latitude, item.location.longitude]);
        markers.region.push(item.members[0].iso3166);
        var label = '<b>'+ item.members[0].location +'</b></br>';
        
        var memberNames = []

        item.members.forEach(member => {
          memberNames.push(member.name);
        });

        memberNames.sort().forEach(name => {
          label += name + '<br>';
        });

        markers.label.push(label);
      });

      this.addMemberMarkers(markers);
    });
    
  }
  
  addMemberMarkers(markers) {
    for (const map in jvmNetworkMap.maps) {
      jvmNetworkMap.maps[map].reset();
      jvmNetworkMap.maps[map].addMarkers([]);
      jvmNetworkMap.maps[map].addMarkers(markers.coords);
    }
  }

  initPropertyViewsMap(data) {
      
    updateTopViewsLists(data);
     
    if (data.hasOwnProperty('propertyViewsLast30Days')) {
      let countryViews = data.propertyViewsLast30Days.views;
      mapviews = valuesToPercent(countryViews, data.propertyViewsLast30Days.total);
      
      $(function(){
        jvmViewsMap = new jvm.MultiMap({
          container: $('#views-map'),
          maxLevel: 1,
          subMapsOptions: {
            backgroundColor: 'transparent',
            regionStyle: {
              initial: {
                fill: '#eeeeee'
              }
            },
            series: {
              regions: [{
                values: mapviews,
                scale: ['#C8EEFF', '#0071A4'],
                normalizeFunction: 'polynomial'
              }]
            },
            onRegionTipShow: function(e, el, code){
              const count = (typeof(mapviews[code]) == 'undefined' || mapviews[code] == 0) ? '' : ' ' + mapviews[code] + '%';
              el.html(el.html()+count);
            }
          },
          main: {
            map: 'world_mill',
            backgroundColor: 'transparent',
            zoomAnimate: true,
            regionStyle: {
              initial: {
                fill: '#eeeeee'
              }
            },
            series: {
              regions: [{
                values: mapviews,
                scale: ['#C8EEFF', '#0071A4'],
                normalizeFunction: 'polynomial'
              }]
            },
            onRegionTipShow: function(e, el, code){
              const count = (typeof(mapviews[code]) == 'undefined' || mapviews[code] == 0) ? '' : ' ' + mapviews[code] + '%';
              el.html(el.html()+ count);
            },
            onMarkerTipShow: function(event, label, index){
              label.html(
                markers.label[index]
              );
            }
          },
          mapNameByCode: function(code, multiMap){
            var submaps = ['AU', 'CA', 'NL', 'NZ', 'US'];
            if (submaps.includes(code)) {
              return code.toLowerCase()+'_mill';
            } else {
              return 'world_mill';
            }
          },
          mapUrlByCode: function(code, multiMap) {
            var submaps = ['AU', 'CA', 'NL', 'NZ', 'US'];
            if (submaps.includes(code)) {
              return '/js/jquery-jvectormap-'+code.toLowerCase()+'-mill.js';
            } else {
              return '/js/jquery-jvectormap-world-mill.js';
            }
          }
        });
      });
    }
    
  }
  
  initNetworkMap() {
    
    var that = this;
    
    $(function(){
      jvmNetworkMap = new jvm.MultiMap({
        container: $('#network-map'),
        zoomStep: 1.9,
        zoomMax: 50,
        main: {
          map: 'world_mill',
          backgroundColor: 'transparent',
          zoomAnimate: true,
          zoomStep: 1.9,
          zoomMax: 50,
          regionStyle: {
            initial: {
              fill: '#7FB4D3'
            }
          },
          markerStyle: {
            initial: {
              fill: '#085E90',
              stroke: 'white'
            },
            hover: {
              "fill-opacity": 1,
              cursor: 'pointer',
              stroke: '#dddddd'
            },
          },
          onRegionTipShow: function(e, el, code){
            el.style.display = 'none';
          },
          onRegionClick: function(e, code){
            let current_map = jvmNetworkMap.history[jvmNetworkMap.history.length - 1];
            var submaps = ['US'];
            if (submaps.includes(code)) {
              that.fetchMembersList();
              that.addMemberMarkers(markers);
            }
            that.clearNetworkMapHistory();
          },
          onMarkerTipShow: function(event, label, index){
            label.html(
              markers.label[index]
            );
          },
          onMarkerClick: function(event, index){
            that.zoomNetworkMapToMarkerRegion(index);
          }
        },
        subMapsOptions: {
          backgroundColor: 'transparent',
          zoomAnimate: true,
          zoomStep: 1.9,
          zoomMax: 50,
          markers: markers.coords,
          regionStyle: {
            initial: {
              fill: '#7FB4D3'
            }
          },
          markerStyle: {
            initial: {
              fill: '#085E90',
              stroke: 'white'
            },
            hover: {
              "fill-opacity": 1,
              cursor: 'pointer',
              stroke: '#dddddd'
            },
          },
          onRegionTipShow: function(e, el, code){
            el.style.display = 'none';
          },
          onRegionClick: function(e, code){
            let current_map = jvmNetworkMap.history[jvmNetworkMap.history.length - 1];
            jvmNetworkMap.history[2] = current_map;
          },
          onMarkerTipShow: function(event, label, index){
            label.html(
              markers.label[index]
            );
          },
          onMarkerClick: function(event, index){
            that.zoomNetworkMapToMarkerRegion(index);
            let current_map = jvmNetworkMap.history[jvmNetworkMap.history.length - 1];
            jvmNetworkMap.history[2] = current_map;
          }
        },
        mapNameByCode: function(code, multiMap){
          var submaps = ['US'];
          if (code.startsWith('US')) {
            return code.toLowerCase()+'_mill';
          } else {
            return 'world_mill';
          }
        },
        mapUrlByCode: function(code, multiMap) {
          var submaps = ['US'];
          if (code.startsWith('US')) {
            return '/js/jquery-jvectormap-'+code.toLowerCase()+'-mill.js';
          } else {
            return '/js/jquery-jvectormap-world-mill.js';
          }
        }
      });
    });
  }
  
  clearNetworkMapHistory() {
    let current_map = jvmNetworkMap.history[jvmNetworkMap.history.length - 1];
    jvmNetworkMap.history.splice(1, 5);
  }
  
  zoomNetworkMapToMarkerRegion(index) {

    var regionCode = markers.region[index].split("-")[0];
    let current_map = jvmNetworkMap.history[jvmNetworkMap.history.length - 1];
    
    if (current_map.params.map == 'us_mill') {
      jvmNetworkMap.drillDown('us_mill', markers.region[index]);
      current_map.setFocus({region: markers.region[index], animate: true});
      
    } else {
      this.clearNetworkMapHistory();
  
      if (regionCode == 'US') {
        jvmNetworkMap.drillDown('us_mill', regionCode);
        this.fetchMembersList();
        this.addMemberMarkers(markers);
      } else {
        jvmNetworkMap.drillDown('world_mill', regionCode);
      }
    }

  }
  
  toggleMoreViewListItems(e) {
    let buttonText = e.target.innerText == 'View More' ? 'View Less' : 'View More';
    this.listsTarget.style.maxHeight = this.listsTarget.offsetHeight;
    let hiddenItems = document.querySelectorAll('.property-view-lists tr.to-hide');
    [].forEach.call(hiddenItems, (row) => {
      
      row.classList.toggle('hide-tr');
      row.classList.toggle('expanded');
        
    });
    
    e.target.innerText = buttonText;
    
  }

  get firebaseController() {
    var firebaseElement = document.getElementById('firebase')
    return this.application.getControllerForElementAndIdentifier(firebaseElement, 'firebase');
  }

}

function updateTopViewsLists(viewData, submap = false) {

  // TODO: make these stimulus targets
  const topCountriesList = document.getElementById('country-views-list');
  const topCitiesList = document.getElementById('city-views-list');

  let countryViews = selectedTimeSpan == '7' ? viewData.countryViewsLast7days : viewData.countryViewsLast30days;
  let cityViews = selectedTimeSpan == '7' ? viewData.cityViewsLast7days : viewData.cityViewsLast30days;
  
  topCountriesList.innerHTML = '';
  topCitiesList.innerHTML = '';

  var countryResults = [];
  var countryTotalPercent = 0;
  var cityResults = [];
  var cityTotalPercent = 0;
  
  var showMoreButton = false;
  
  const upArrow = `<span class="flex justify-end"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-3.5 text-green-600">
    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
  </svg></span>`;
  const downArrow = `<span class="flex justify-end"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-3.5 text-red-600">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
  </svg></span>`;
  
  if (cityViews) {
    cityViews.views.forEach((item) => {
      let cityName = item.city;
      if (item.iso.includes('AU-') || item.iso.includes('CA-') || item.iso.includes('US-')) {
        cityName = item.city + ', ' + item.iso.split('-')[1];
      }
    
      if (item.viewsPercentage >= 1 && item.iso != null) {
        cityTotalPercent += item.viewsPercentage;
        changeArrow = item.changePercentage < 0 ? downArrow : upArrow;
        cityResults.push({area: cityName, viewsPercentage: item.viewsPercentage, code: item.iso.split('-')[0].toLowerCase(), change: changeArrow});
      }
    
    });
  }

  if (countryViews) {
    countryViews.views.forEach((item) => {
      
      if (item.viewsPercentage >= 1 && item.iso != null) {
        cityTotalPercent += item.viewsPercentage;
        changeArrow = item.changePercentage < 0 ? downArrow : upArrow;
        countryResults.push({area: I18n.t('iso3166.'+item.iso), viewsPercentage: item.viewsPercentage, code: item.iso.split('-')[0].toLowerCase(), change: changeArrow});
      }
    
    });
  }


  cityResults.sort((a, b) => (a.viewsPercentage < b.viewsPercentage || a.code == 'other') ? 1 : -1);
  for (const value in cityResults) {
    let hidden = value < 10 ? '' : 'hide-tr to-hide';
    topCitiesList.innerHTML += '<tr class="h-6 mb-3 dark:border-gray-800 '+hidden+'"><td class="pl-4 pb-2.5"><span class="flag-icon '+cityResults[value].code+'"></span>' + ' ' +cityResults[value].area + ' </td><td class="py-2.5 text-right">' + cityResults[value].viewsPercentage + '%</td><td class="pr-4 py-2.5 text-right">'+cityResults[value].change+'</td></tr>'
  }

  countryResults.sort((a, b) => (a.viewsPercentage < b.viewsPercentage|| a.code == 'other') ? 1 : -1);
  for (const value in countryResults) {
    let hidden = value < 10 ? '' : 'hide-tr to-hide';
    topCountriesList.innerHTML += '<tr class="h-6 mb-3 dark:border-gray-800 '+hidden+'"><td class="pl-4 pb-2.5"><span class="flag-icon '+countryResults[value].code+'"></span>' + ' ' +countryResults[value].area + ' </td><td class="py-2.5 text-right">' + countryResults[value].viewsPercentage + '%</td><td class="pr-4 py-2.5 text-right">'+countryResults[value].change+'</td></tr>'
  }

}


function updateMapValues(newValues, submap = false) {

  let countryViews = selectedTimeSpan == 7 ? newValues.propertyViewsLast7Days.views : newValues.propertyViewsLast30Days.views;
  mapviews = selectedTimeSpan == 7 ? valuesToPercent(countryViews, newValues.propertyViewsLast7Days.total) : valuesToPercent(countryViews, newValues.propertyViewsLast30Days.total);

  for (const map in jvmViewsMap.maps) {
    jvmViewsMap.maps[map].reset();
    jvmViewsMap.maps[map].removeAllMarkers();
    jvmViewsMap.maps[map].series.regions[0].setValues(mapviews);
  }
  // submap = (!submap || submap == 'world') ? false : submap.toUpperCase();
  submap = false;
  updateTopViewsLists(newValues, submap);
}

function updateViewsMapTabs(activeTab) {
  var tabs = document.querySelectorAll('.property-map-button');
  [].forEach.call(tabs, (tab) => {
    if (tab == activeTab) {
      tab.classList.add("border-gray-500", "text-gray-600", "dark:text-gray-300/75", "dark:border-gray-300/75", "active");
      tab.classList.remove("border-transparent", "text-gray-500", "dark:text-gray-400/75", "hover:text-gray-700", "dark:hover:text-gray-500", "hover:border-gray-300", "hidden", "dark:hover:border-gray-500");
    } else {
      tab.classList.remove("border-gray-500", "text-gray-600", "dark:text-gray-300/75", "dark:border-gray-300/75", "active");
      tab.classList.add("border-transparent", "text-gray-500", "dark:text-gray-400/75", "hover:text-gray-700", "dark:hover:text-gray-500", "hover:border-gray-300", "dark:hover:border-gray-500");
    }
  });
}

function updateDurationTabs(activeTab) {
  var tabs = document.querySelectorAll('.property-duration-button');
  [].forEach.call(tabs, (tab) => {
    if (tab == activeTab) {
      tab.classList.add("text-gray-900", "bg-white", "dark:bg-gray-700", "dark:text-gray-300", "shadow-sm", "ring-1", "ring-black", "shadow-sm", "ring-1", "ring-black", "ring-opacity-5", "flex", "rounded-md");
      tab.classList.remove("text-gray-600", "dark:text-gray-400", "group-hover:text-gray-900", "dark:group-hover:text-gray-300");
    } else {
      tab.classList.add("text-gray-600", "dark:text-gray-400", "group-hover:text-gray-900", "dark:group-hover:text-gray-300");
      tab.classList.remove("text-gray-900", "bg-white", "dark:bg-gray-700", "dark:text-gray-300", "shadow-sm", "ring-1", "ring-black", "bg-white", "shadow-sm", "ring-1", "ring-black", "ring-opacity-5", "flex", "rounded-md");
    }
  });
}

function percentToOneDecimal(value, of) {
  let percent = (value / of * 100).toFixed(1);
  return parseFloat(percent)
}

function valuesToPercent(values, total) {
  var percentObject = {};
  for (let val in values) {
    if (val.includes('-')) {
      let mapCode = val.split('-')[0];
      percentObject[val] = parseFloat((values[val] / values[mapCode] * 100).toFixed(1));
    } else {
      percentObject[val] = parseFloat((values[val] / total * 100).toFixed(1));
    }
  }
  return percentObject;
}

function failureCallback(err) {
  console.log("An error occured: " + err);
}

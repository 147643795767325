var searchQueue;
var autocomplete;
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="geocoder"
export default class extends Controller {

  static targets = ['feederMarketForm', 'feederMarketInput', 'results']
  
  feederMarketInputTargetConnected() {
    var that = this;
    autocomplete = new google.maps.places.Autocomplete(
      this.feederMarketInputTarget, {fields: ['place_id', 'name'], types: ['(regions)']}
    );
    autocomplete.addListener('place_changed', function() {
      var place = autocomplete.getPlace();
      that.findByPlaceId(place);
    });
  }
  
  findByPlaceId(place) {
    if (place) {
      var that = this;
      var geocoder = new google.maps.Geocoder();
      var placeID = place.place_id;
      geocoder.geocode( { 'placeId': placeID}, function(result, status) {
        var placeResult = Array.isArray(result) ? result[0] : result;
        
        if (placeResult) {
          that.addFeederMarketByPlace(placeResult);
        } else {
          that.selectFirstFeederMarket();
        }
      });
    } else {
      this.selectFirstFeederMarket();
    }
    
  }
  
  addFeederMarketByPlace(place) {
    this.feederMarketFormTarget.innerHTML = `<span class="text-gray-400 dark:text-gray-600 text-xs">${place.formatted_address}</span>`;
    var newFeederMarketForm = document.getElementById('new_profile_feeder_market');
    
    newFeederMarketForm.querySelector('#feeder-market-address').value = place.formatted_address;
    newFeederMarketForm.querySelector('#feeder-market-status').value = 'geocoded';
    newFeederMarketForm.querySelector('#feeder-market-response_code').value = 200;
    newFeederMarketForm.querySelector('#feeder-market-lat').value = place.geometry.location.lat();
    newFeederMarketForm.querySelector('#feeder-market-lng').value = place.geometry.location.lng();
    newFeederMarketForm.querySelector('#feeder-market-north').value = place.geometry.bounds.getNorthEast().lat();
    newFeederMarketForm.querySelector('#feeder-market-south').value = place.geometry.bounds.getSouthWest().lat();
    newFeederMarketForm.querySelector('#feeder-market-east').value = place.geometry.bounds.getNorthEast().lng();
    newFeederMarketForm.querySelector('#feeder-market-west').value = place.geometry.bounds.getSouthWest().lng();
    
    newFeederMarketForm.submit();
  }
  
  selectFirstFeederMarket() {
    var that = this;
    var firstResult = document.querySelector('.pac-container .pac-item:first-of-type').textContent;
    var formattedFirstResult = firstResult.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
    var geocoder = new google.maps.Geocoder();
    
    this.feederMarketInputTarget.value = formattedFirstResult;
    this.feederMarketInputTarget.disabled = true;
    this.feederMarketFormTarget.innerHTML = `<span class="text-gray-400 dark:text-gray-600 text-xs">${formattedFirstResult}</span>`;
    geocoder.geocode({"address": firstResult }, function(result, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        that.addFeederMarketByPlace(result[0])
      } else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
        geocoder.geocode({"address": formattedFirstResult }, function(result, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            that.addFeederMarketByPlace(result[0]);
          }
        });
      }
    });
  }

}

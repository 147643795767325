import etjs from "etjs"
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="analytics"
export default class extends Controller {
  
  static targets = [ "et" ]
  
  static values = {
    isproduction: Boolean
  }
  
  etTargetConnected() {
    etjs.init({
        gaCode: 'UA-1965509-2',
        gaV4Code: 'G-RRXL4BBBM4',
        isProduction: this.isproductionValue,
        suppressWarnings: this.isproductionValue,
        debugger: !this.isproductionValue,
    });
    
  }
}
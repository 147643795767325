import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {

  static targets = ['window']

  static values = {
    targetId: String
  }


  open() {
    this.windowTarget.classList.remove('hidden');
  }

  close() {
    this.windowTarget.classList.add('hidden');
  }

  enter() {
    var modalWidow = document.getElementById(this.targetIdValue);
    modalWidow.classList.add('open');
  }

  exit() {
    var modalWidow = document.getElementById(this.targetIdValue);
    modalWidow.classList.remove('open');
  }

}

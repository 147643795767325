import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {
  
  static targets = ['lightCheck', 'darkCheck', 'autoCheck'];

  connect() {
    this.updateTheme();
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      this.updateTheme();
    });
  }
  
  updateTheme(e) {
    if (e) {
      this.theme = e.target.dataset.themeSelected;
    }
    this.setHTMLClass(this.theme);
    this.updateSelectedMenuItem(this.theme);
    this.broadcastThemeChange();
  }
  
  setHTMLClass(theme) {
    if (this.theme === 'dark' || ((this.theme === 'auto' || !('theme' in localStorage)) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }
  
  updateSelectedMenuItem(theme) {
    let checks = document.querySelectorAll('.theme-check');
    [].forEach.call(checks, (check) => {
      check.classList.add('hidden');
    });
    if (checks.length > 0) {
      this.targets.find(theme+'Check').classList.remove('hidden');
    }
  }
  
  broadcastThemeChange() {
    const event = document.createEvent('Event');
    event.initEvent('themeChange', true, true);
    document.dispatchEvent(event);
  }
  
  set theme(value) {
    window.localStorage.setItem('theme', value);
  }
  
  get theme() {
    var savedTheme = window.localStorage.theme;
    var selectedTheme = savedTheme == 'dark' || savedTheme == 'light' || savedTheme == 'auto' ? savedTheme : 'auto';
    return selectedTheme;
  }

}
